<template>
    <!-- CIRCULAR PROGRESS INDICATOR -->
    <v-container style="height: 100vh;" v-if="blogList.length === 0 || imgLinkList.length === 0">
        <v-col style="height: 100vh;" align-self="center" align="center" justify="center">
            <v-row style="height: 100vh;" justify="center" align="center">
                <v-progress-circular
                    indeterminate
                    color="ideenBlue"
                    :size="90"
                    :width="9"
                ></v-progress-circular>
            </v-row>
        </v-col>
    </v-container>

    <!-- BLOG ENTRY SCREEN -->
    <v-container
        v-else
        fluid
        class="pa-0"
        id="scroll-target"
        v-scroll.self="onScroll"
    >
        <v-row class="mb-10 no-gutters">
            <!-- BLOG ENTRY COLUMN -->
            <v-col cols="12" lg="8">
                <v-row class="no-gutters">
                    <v-col cols="12" lg="11" class="no-gutters">
                        <!-- BACK BUTTON -->
                        <v-row class="px-0 px-lg-10 pt-0 pt-lg-12 no-gutters">
                            <v-btn
                                text
                                x-large
                                color="black"
                                class="boldText"
                                to="/blog"
                                plain
                            >
                                <v-icon left large color="orange">
                                    mdi-arrow-left
                                </v-icon>
                                BLOG
                            </v-btn>
                        </v-row>

                        <!-- ENTRY TITLE -->
                        <v-row class="mt-0 mt-lg-2 px-4 px-lg-16 no-gutters">
                            <p class="entryTitle">{{ blogList[0]['title'] }}</p>
                        </v-row>

                        <!-- ENTRY COVER -->
                        <v-row class="mb-16 px-lg-16 no-gutters">
                            <!-- Se tiene que cambiar la liga para obtenerla la imagen dinámicamente -->
                            <v-img
                                class="mt-0 mt-lg-3"
                                :src="(imgLinkList[0][0])"
                            ></v-img>
                        </v-row>

                        <!-- ENTRY CONTENT -->
                        <v-row class="mt-16 px-4 px-lg-16 no-gutters" style="width: 100%; height: auto;">
                            <p v-html="blogList[0]['content']"></p>
                        </v-row>
                    </v-col>
                </v-row>

                <!-- DESKTOP RELATED ENTRIES LIST -->
                <!-- TODO ADD RELATED ENTRIES LIST -->
<!--                <div class="hidden-sm-and-down related pt-10 mt-14">-->
<!--                    <BlogRelatedEntry_List></BlogRelatedEntry_List>-->
<!--                </div>-->
            </v-col>

            <!-- BLOG DETAILS COLUMN -->
            <v-col cols="12" lg="4" class="pa-0" style="background-color: #F4F6FD;">
                <v-banner app sticky class="pa-0 pt-6" tile shaped style="background-color: #F4F6FD">
                    <v-col cols="12" class="pl-4">
                        <!-- ENTRY DESCRIPTION -->
                        <v-row class="mt-2">
                            <v-col>
                                <v-row>
                                    <p class="customText pr-16 mr-16">{{ blogList[0]['description'] }}</p>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- ENTRY AUTHOR -->
                        <v-row class="mt-4">
                            <v-col>
                                <v-row>
                                    <p class="boldText">{{ blogText[0]['author'] }}</p>
                                </v-row>
                                <v-row class="mt-0">
                                    <p class="customText">{{ blogList[0]['author'] }}</p>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- ENTRY CATEGORY -->
                        <v-row class="mt-4">
                            <v-col>
                                <v-row>
                                    <p class="boldText">{{ blogText[0]['category'] }}</p>
                                </v-row>
                                <v-row class="mt-0">
                                    <p class="customText text-capitalize">{{ blogList[0]['category'] }}</p>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- ENTRY DATE -->
                        <v-row class="mt-4">
                            <v-col>
                                <v-row>
                                    <p class="boldText">{{ blogText[0]['date'] }}</p>
                                </v-row>
                                <v-row class="mt-0">
                                    <p class="customText">{{ blogList[0]['publishDate'].toDate().toDateString() }}</p>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- SHARE BLOG POST -->
                        <v-row class="mt-8">
                            <v-col>
                                <v-row>
                                    <p class="customText">
                                        {{ blogText[0]['share'] }}
                                    </p>
                                </v-row>
                                <v-row class="mt-2 mb-0">
                                    <v-btn
                                        color="black"
                                        @click="copyText"
                                    >
                                        <v-icon
                                            color="white"
                                            x-large>
                                            mdi-link
                                        </v-icon>
                                    </v-btn>
                                </v-row>
                                <v-row>
                                    <v-alert
                                        outlined
                                        color="#0131B7"
                                        icon="mdi-check"
                                        text
                                        dense
                                        :value="show"
                                    >
                                        {{ blogText[0]['prompt'] }}
                                    </v-alert>
                                </v-row>
                                <v-row v-if="!show">
                                    <p class="normalText">
                                        {{ blogText[0]['urlCopy'] }}
                                    </p>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-banner>
            </v-col>
        </v-row>

        <!-- MOBILE RELATED ENTRIES LIST -->
        <!-- TODO ADD RELATED ENTRIES LIST -->
<!--        <v-row class="hidden-md-and-up no-gutters mt-n12">-->
<!--            <div class="related">-->
<!--                <BlogRelatedEntry_List-->
<!--                    :title="blogList[0]['title']"-->
<!--                    :category="blogList[0]['category']"-->
<!--                    :selected="blogEntryId"-->
<!--                    :imageList="imgLinkList"-->
<!--                ></BlogRelatedEntry_List>-->
<!--            </div>-->
<!--        </v-row>-->
    </v-container>
</template>

<script>
// import BlogRelatedEntry_List from "@/components/blog/BlogRelatedEntry_List";
import * as db_local from "../../constants/blog_database";
import firebase from "firebase";
import {db} from "@/plugins/firebaseConfig";

export default {
    name: "BlogEntry_Screen",
    // components: {BlogRelatedEntry_List},
    data() {
        return {
            blogEntryId: null,
            show: false,
            blogList: [],
            imgList: [],
            imgRefList: [],
            imgLinkList: [],
            blogText: null,
            scrollInvoked: 0
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    beforeMount() {
        this.blogEntryId = this.$route.params.id

        //Base de datos de blog
        if (this.language === 'es') {
            this.blogText = db_local.blog_entry_text_es;
        } else {
            this.blogText = db_local.blog_entry_text_en;
        }
    },

    async mounted() {
        await firebase.firestore().collection('fl_content').doc(this.blogEntryId).get().then((snapshot) => {
            this.blogList.push(snapshot.data())
        });

        await this.getImageDocReference()
    },

    methods: {
        copyText() {
            navigator.clipboard.writeText(window.location.href);
            this.show = true;
            setTimeout(() => {
                this.show = false
            }, 1500)
        },

        async getImageDocReference() {
            let imageTempList = [];
            for (let i = 0; i < this.blogList.length; i++) {
                this.imgRefList.push(this.blogList[i]['coverImg'])
            }
            for (let i = 0; i < this.blogList.length; i++) {
                //Formato para el path según firebase
                //this.imgRefList[i][0].path
                //Formato para el nombre del documento de la otra colección
                //this.imgRefList[i][0]._delegate._key.path.segments[6]
                let docRef = db.collection("fl_files").doc(this.imgRefList[i][0]._delegate._key.path.segments[6])
                await docRef.get().then((doc) => {
                    if (doc.exists) {
                        this.imgList.push(doc.data())
                    }
                })
            }
            for (let i = 0; i < this.blogList.length; i++) {
                let storage = firebase.storage()
                let storageRef = storage.ref()
                let imgRef = storageRef.child('flamelink/media' + '/' + this.imgList[i]['file'])
                await imgRef.getDownloadURL().then(function (url) {
                    imageTempList.push(url)
                })
            }
            this.imgLinkList.push(imageTempList)
        },

        onScroll () {
            this.scrollInvoked++
        },
    }
}
</script>

<style scoped>
* {
    font-family: "Montserrat", sans-serif;
    font-size: 1em;
}

.customText {
    color: #464646;
}

.boldText {
    font-weight: bold;
    font-size: 1.25em;
    color: #464646;
    font-family: Montserrat, sans-serif;
}

.entryTitle {
    font-size: x-large;
    font-weight: normal;
    color: #464646;
    font-family: Montserrat, sans-serif;
}

.related {
    padding-top: 5rem;
    background-color: #F4F6FD;
}

@media (max-width: 768px) {
    .related {
        padding-top: 0 !important;
    }

    .entryTitle {
        font-size: 1.2em;
        font-weight: normal;
        color: #464646;
    }
}

::-webkit-scrollbar {
    width: 2px;
    /* Para quitar la scrollbar cambiar el ancho a 0
    width: 0px;
     */
}

::-webkit-scrollbar-thumb {
    background: #0131B7;
    border-radius: 6px;
}
</style>