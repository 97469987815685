
export const blog_list_es = [
    {
        arrowBack: "ATRÁS",
        arrowNext: "SIGUIENTE"
    }
]

export const blog_list_en = [
    {
        arrowBack: "BACK",
        arrowNext: "NEXT"
    }
]

export const blog_list_title = [
    "Entradas Recientes del Blog",
    "Latest Blog Entries",
];

export const blog_entry_text_es = [
    {
        author: "Autor",
        category: "Categoría",
        date: 'Fecha',
        share: "¡Compártelo!",
        urlCopy: "Haz click en el botón para copiar URL",
        prompt: "Link copiado al portapapeles",
    }
]

export const blog_entry_text_en = [
    {
        author: "Author",
        category: "Category",
        date: 'Publish Date',
        share: "Share this entry!",
        urlCopy: "Copy to clipboard",
        prompt: "Link copied to clipboard",
    }
]

export const blog_titles_es = [
    {
        relatedEntry: "Artículos relacionados"
    }
]

export const blog_titles_en = [
    {
        relatedEntry: "Related Entries"
    }
]

export const blog_structure_local = [
    {
        author: "Autor",
        content: "<h1>Este es el titulo</h1><p>hola mundo</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id volutpat massa. Vivamus sed pellentesque neque, et ullamcorper diam. Vestibulum lacinia purus in laoreet dictum. Nullam nec gravida dolor. In rutrum lacus ut massa mattis lacinia. Integer dapibus, mauris ut laoreet gravida, lorem enim interdum nisl, ut faucibus tellus sapien ut est. Vestibulum bibendum nulla ut efficitur dignissim. Suspendisse posuere sapien sollicitudin feugiat hendrerit. Fusce egestas lacus nec lectus aliquam semper. Phasellus at nunc id magna ultrices scelerisque. Morbi suscipit neque vitae felis scelerisque, ut efficitur ex sollicitudin. Maecenas quis tempor nibh, sit amet facilisis neque. In commodo sit amet diam non malesuada. Donec vestibulum turpis at efficitur porta. Etiam et venenatis nibh, in lobortis urna. Aliquam dignissim elementum turpis, eget bibendum velit sagittis euismod.</p>\n" +
            "<p></p>\n" +
            "<p>Suspendisse elementum elementum tincidunt. Praesent placerat quam sit amet finibus lacinia. Quisque gravida, eros id viverra dignissim, sem quam suscipit magna, quis sollicitudin dolor tellus non orci. Donec eu aliquet lorem. Aenean elementum velit rutrum, congue tellus ut, volutpat quam. Nam dolor enim, luctus eu efficitur vitae, dapibus ut nibh. Nulla facilisi. Etiam erat lectus, lacinia vel consequat quis, aliquam in nibh. Sed risus eros, euismod sit amet sodales eu, vestibulum in nisl. Cras vehicula mollis molestie. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>\n" +
            "<p></p>\n" +
            "<p>Morbi tempus leo sagittis auctor dignissim. Suspendisse vitae libero congue, hendrerit arcu quis, tempus eros. Nulla viverra tincidunt justo. Praesent commodo massa at sodales imperdiet. Cras eu tellus a quam ullamcorper ornare. Curabitur vulputate mi quis efficitur porttitor. Etiam at purus ut nibh ullamcorper lobortis. Donec vulputate elit eu lectus aliquam finibus. Aliquam suscipit neque vitae risus varius luctus. Nunc in aliquam dolor. Nulla consectetur mauris non finibus tristique. Nulla facilisi. Aliquam non nulla lacus. Nulla ac metus ac nulla blandit posuere. Cras condimentum turpis non quam hendrerit, blandit pharetra libero pharetra.</p>\n" +
            "<p></p>\n" +
            "<p>Suspendisse dignissim orci leo, posuere tincidunt odio porttitor eget. Maecenas vehicula quam vel ligula volutpat, ut feugiat ex porta. Ut nec arcu at metus maximus pharetra. Maecenas quis sodales nibh, at ultricies libero. Nunc ut pulvinar odio. Nam elit augue, laoreet eget turpis ut, gravida ultrices magna. Sed ultrices nisi et imperdiet laoreet. Sed tempus ac felis quis efficitur. Ut facilisis laoreet turpis, quis porta metus scelerisque quis. Aliquam quis arcu at neque feugiat feugiat.</p>\n" +
            "<p></p>\n" +
            "<p>Donec vel risus diam. Sed imperdiet odio justo, facilisis condimentum felis iaculis a. Praesent ac viverra tellus. Nullam sed ex magna. Curabitur tempus elit nec dui vehicula pharetra. Fusce rhoncus leo eget sapien imperdiet dictum. Curabitur diam dui, mattis blandit velit non, fermentum tempus sem. Vestibulum et mauris nunc.</p>",
        coverImg: "computer2.jpg",
        creationDate: "date",
        description: "Descripción breve del artículo en un máximo de 250 caracteres para que se muestre completa",
        publishDate: "17/05/2022",
        title: "Lorem ipsum dolor sit amet, consectetuer adipiscing ",
        category: "salsas"
    },
]